var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px", model: _vm.codeSettingData } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("lang_please_enter_a_separator") } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("lang_please_enter_a_separator") },
                model: {
                  value: _vm.codeSettingData.split,
                  callback: function ($$v) {
                    _vm.$set(_vm.codeSettingData, "split", $$v)
                  },
                  expression: "codeSettingData.split",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("lang_add_components") } },
            [
              _c("el-button", {
                attrs: {
                  type: "primary",
                  circle: "",
                  size: "small",
                  icon: "el-icon-plus",
                },
                on: { click: _vm.addCodePart },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _vm._l(_vm.codeSettingData.parts, function (part, index) {
                return [
                  _c("div", { key: index, staticClass: "group" }, [
                    _c(
                      "div",
                      [
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            circle: "",
                            size: "small",
                            icon: "el-icon-delete",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeCodePart(index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "group-item" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "lang_please_select_component_type"
                              ),
                            },
                            model: {
                              value: part.type,
                              callback: function ($$v) {
                                _vm.$set(part, "type", $$v)
                              },
                              expression: "part.type",
                            },
                          },
                          _vm._l(_vm.itemTypes, function (itemType, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: itemType.label,
                                value: itemType.key,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    part.type == "CONSTANTS"
                      ? _c(
                          "div",
                          { staticClass: "group-item" },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_enter_constant_value"
                                ),
                              },
                              model: {
                                value: part.value,
                                callback: function ($$v) {
                                  _vm.$set(part, "value", $$v)
                                },
                                expression: "part.value",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    part.type == "VARIABLE"
                      ? _c(
                          "div",
                          { staticClass: "group-item" },
                          [
                            part.type == "VARIABLE"
                              ? _c("variable-selector", {
                                  attrs: { paramsOptions: _vm.paramsOptions },
                                  model: {
                                    value: part.varName,
                                    callback: function ($$v) {
                                      _vm.$set(part, "varName", $$v)
                                    },
                                    expression: "part.varName",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    part.type == "SERIAL_NUMBER"
                      ? _c(
                          "div",
                          { staticClass: "group-item" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "lang_please_select_serial_number_type"
                                  ),
                                },
                                model: {
                                  value: part.serialNumber.type,
                                  callback: function ($$v) {
                                    _vm.$set(part.serialNumber, "type", $$v)
                                  },
                                  expression: "part.serialNumber.type",
                                },
                              },
                              _vm._l(
                                _vm.serialTypes,
                                function (serialType, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: serialType.label,
                                      value: serialType.key,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    part.type == "SERIAL_NUMBER" && part.serialNumber
                      ? _c(
                          "div",
                          { staticClass: "group-item" },
                          [
                            part.serialNumber.type == "VARIABLE"
                              ? _c("variable-selector", {
                                  attrs: { paramsOptions: _vm.paramsOptions },
                                  model: {
                                    value: part.serialNumber.varName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        part.serialNumber,
                                        "varName",
                                        $$v
                                      )
                                    },
                                    expression: "part.serialNumber.varName",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    part.type == "SERIAL_NUMBER" && part.serialNumber
                      ? _c(
                          "div",
                          { staticClass: "group-item" },
                          [
                            _c("el-input-number", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_select_the_maximum_length"
                                ),
                                min: 1,
                                max: 10,
                              },
                              model: {
                                value: part.serialNumber.length,
                                callback: function ($$v) {
                                  _vm.$set(part.serialNumber, "length", $$v)
                                },
                                expression: "part.serialNumber.length",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }