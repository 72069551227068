





























































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VariableSelector from '@/components/flow-design/flow-config/node-variable-selector.vue';
import CodeGeneratorSetting from '@/components/flow-design/flow-config/code-generator-setting.vue';
import FileInput from '@/components/file-input/file-input.vue';
import { getFlowParamDataSource, getRealities, saveFlow, getFlow } from '@/api/flow-design/FlowApi';
import { getFormConfig } from '@/api/form-design/FormApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { FlowData, FlowModel } from '@/models/flow/FlowModel';
import { WidgetDataTypeDict, WidgetDataTypeEnum } from '@/models/form/WidgetModel';
import { FormParamSettingData, ParamOptionData, ParamOptionModel, ParamTypeModel, ValueItemModel } from '@/models/flow/FlowConfigModel';
import { buildFormWidgetTree } from '@/components/form-design/utils';
import AppApi from '@/api/application/AppApi';
import { throttle } from 'lodash';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';

@Component({
  name: 'FlowConfig',
  components: {
    VariableSelector,
    CodeGeneratorSetting,
    FileInput
  },
  // 解决编辑报错
  filters: {
    noTDefault(val) {
      if (!val) {
        val = [];
      }
      return val;
    }
  }
})
export default class FlowConfig extends Vue {
  // 应用id
  @Prop({ default: '' }) applicationId!: string;
  @Prop() flowId!: string;
  flowConfig: FlowModel = SystemUtil.cloneDeep(FlowData);

  @Prop({ default: false })
  visible!: boolean; // 流程设置弹框 的显示隐藏
  flowConfigDialogVisiable: boolean = false;
  flowConfigDialogVisiableConst: boolean = true;

  innerParamDialogVisiable: boolean = false;

  paramsTypes: Record<string, string>[] = WidgetDataTypeDict;
  setValueItemsDialogFlag: boolean = false;
  currentParamOption: ParamOptionModel = ParamOptionData;
  valueItems: ValueItemModel[] = [];
  importFormDialogVisible: boolean = false;
  selectedFormItemValue: Record<string, any>[] = [];
  formWidgetTree: Record<string, any>[] = [];
  formParamsOptions: Record<string, any>[] = [];
  formList: Record<string, any>[] = [];
  widgetDataTypeEnum: any = WidgetDataTypeEnum;
  realityList: any[] = [];

  // 默认值表单项树的配置
  formWidgetTreeFormat(node) {
    return {
      id: node.id,
      label: node.label,
      children: node.children
    };
  }
  created(): void {
    this.initData();
  }

  initData() {
    this.getFormList(); // 获取表单列表
    this.getFlowConfig();
    this.getRealityList();
  }

  getFlowConfig() {
    if (this.flowId) {
      getFlow(this.flowId, {}).then((res: any) => {
        if (res.code == '1') {
          let flowConfig = res.data;
          if (
            flowConfig &&
            flowConfig.customBpmModel &&
            flowConfig.customBpmModel.config &&
            flowConfig.customBpmModel.config.paramsOptions
          ) {
            flowConfig.customBpmModel.config.paramsOptions.forEach(item => {
              if (!item.defaultValue) {
                item.defaultValue = null;
              }
            });
          }
          this.flowConfig = flowConfig;
          this.getFormModal(this.flowConfig.customBpmModel.config.formId);
        } else {
          this.flowConfig = SystemUtil.cloneDeep(FlowData);
        }
      });
    }
  }
  // 获取表单列表
  getFormList(): void {
    this.formList = [];
    AppApi.getApplicationLinkFormList({ applicationId: this.applicationId }).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        res.data.forEach(element => {
          this.formList.push(element.link);
        });
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 获取流程图对应的表单数据
  getFormModal(formId: string) {
    getFormConfig({ id: formId }).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        if (res.data.container.components) {
          // 构建表单项树
          this.formWidgetTree = buildFormWidgetTree(res.data.container.components);
          this.formParamsOptions = res.data.container.paramsOptions;
        }
      } else {
        // this.$message.error(res.message);
      }
    });
  }

  /**
   * 获取realitiy
   */
  getRealityList() {
    getRealities({}).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.realityList = res.data;
      } else {
        this.realityList = [];
      }
    });
  }

  /**
   * 为每个类型为集合或者数组的流程参数构建自己的数据源选项
   */
  buildDataSourceForParams() {
    if (this.flowConfig.customBpmModel && this.flowConfig.customBpmModel.config.paramsOptions) {
      let paramsOptions = this.flowConfig.customBpmModel.config.paramsOptions;
      paramsOptions.forEach(paramItem => {
        if (!paramItem.type.valueItems) {
          // paramItem.type.valueItems = [];
          this.$set(paramItem.type, 'valueItems', []);
        }
        if (paramItem.type.reality == 'custom_options') {
        } else {
          if (this.isCollectorOrTree(paramItem)) {
            let params = {
              formId: this.flowConfig.customBpmModel.config.formId,
              processId: this.flowConfig.id,
              paramsOption: paramItem,
              codes: paramItem.defaultValue
            };
            getFlowParamDataSource(params).then((res: any) => {
              let result = [];
              if (res.code == '1' && res.data && res.data.length) {
                result = res.data;
              }
              paramItem.type.valueItems = result;
            });
          }
        }
      });
    } else {
      this.flowConfigDialogVisiable = true;
    }
  }

  normalizerHandler(node) {
    return {
      id: node.code,
      label: node.text,
      children: node.children
    };
  }

  /**
   * description  流程设置弹框确定时,要调一下保存的接口，才能在线条设置属性时出现compare数据
   * @param {}
   * @returns {}
   */
  saveFlowConfig() {
    this.resetConfig();
    this.flowConfig.applicationId = this.applicationId;
    saveFlow(this.flowConfig).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.flowConfig.id = res.data.id;
        this.$emit('update:visible', false);
        this.$emit('saved', this.flowConfig);
        this.$message.success(res.message);
        this.flowConfig = SystemUtil.cloneDeep(FlowData);
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * description  // 导入表单项
   * @param {}
   * @returns {}
   */
  showFormItemImportDialog() {
    if (this.validateFlowParam()) {
      this.importFormDialogVisible = true;
    }
  }

  /**
   * description  导入表单项弹框的 打开选择表单项下拉框时
   * @param {}
   * @returns {}
   */
  isSelectedForm() {
    if (!this.flowConfig.customBpmModel.config.formId) {
      this.$message.warning(this.$t('lang_please_select_a_form_first') as any);
      return;
    }
  }

  /**
   * description  导入表单项弹框 确定时
   * @param {}

   * @returns {}
   */
  saveFormItemConfig() {
    this.selectedFormItemValue.map(item => {
      let obj = this.paramsTypes.find(el => el.value == item.dataType);
      let _type: ParamTypeModel = {
        text: obj ? obj.text : '',
        value: item.dataType,
        reality: item.reality,
        truthType: item.truthType,
        valueItems: item.valueItems
      };
      this.flowConfig.customBpmModel.config.paramsOptions.push({
        name: item.compCode,
        type: _type,
        defaultValue: '',
        formImport: true,
        text: item.label,
        global: false
      });
    });
    this.importFormDialogVisible = false;
  }

  /**
   * description 选择表单时
   * @param {string}   表单id
   * @returns {number}
   */
  changeForm(val) {
    // 获取选择的表单详情
    this.getFormModal(val);
  }

  /*
   *@description:流程设置弹框内, 流程参数设置的添加按钮事件
   *@author: gongcaixia
   *@date: 2021-04-25 13:33:03
   */
  addFlowParam() {
    if (this.validateFlowParam()) {
      if (!this.flowConfig.customBpmModel.config.paramsOptions) this.flowConfig.customBpmModel.config.paramsOptions = [];
      this.flowConfig.customBpmModel.config.paramsOptions.push({
        name: '',
        type: {
          text: '',
          truthType: '',
          reality: '',
          value: '',
          valueItems: []
        },
        defaultValue: '',
        text: '',
        global: false
      });
    }
  }

  /**
   * 增加一行表单参数映射配置
   */
  addFormParamSetting(): void {
    if (this.flowConfig.customBpmModel.config.formParamSettings.length > 0) {
      let item =
        this.flowConfig.customBpmModel.config.formParamSettings[this.flowConfig.customBpmModel.config.formParamSettings.length - 1];
      if (!item.formParamName || !item.processParamName) {
        this.$message.warning(this.$t('lang_perfect_param_config') as any);
        return;
      }
    }
    this.flowConfig.customBpmModel.config.formParamSettings.push(FormParamSettingData());
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的添加按钮时的 校验
   *@author: gongcaixia
   *@date: 2021-04-27 09:12:12
   */
  validateFlowParam() {
    let flag = true;
    if (this.flowConfig.customBpmModel.config.paramsOptions.length > 0) {
      let item = this.flowConfig.customBpmModel.config.paramsOptions[this.flowConfig.customBpmModel.config.paramsOptions.length - 1];
      if (!item.name || !item.type || !item.text) {
        this.$message.warning(this.$t('lang_perfect_param_config') as any);
        flag = false;
      }
    } else {
      flag = true;
    }
    return flag;
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时 默认值置空
   *@author: gongcaixia
   *@date: 2021-04-25 14:04:12
   *@params1: 当前行信息
   */
  changeFlowParamType(type, row) {
    let curType: any = this.paramsTypes.find(item => item.value == type);
    row.text = curType.text;
    row.type.truthType = curType.value;
    row.defaultValue = null;
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 点击类型下拉的每项时触发，当类型为自定义时 ，用户能手输内容
   *@author: gongcaixia
   *@date: 2021-04-25 17:03:21
   *@params1: 当前行信息
   */
  changeFormItemDataType(row) {
    if (row.type.value == 'DEFINITION') {
      this.$prompt('请输入自定义内容', this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        inputValue: row.type.text
      })
        .then((value: any) => {
          row.type.text = value.value;
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
    }
  }

  isCollectorOrTree(formItem) {
    return (
      formItem.type && (formItem.type.value == this.widgetDataTypeEnum.TREE || formItem.type.value == this.widgetDataTypeEnum.COLLECTOR)
    );
  }

  /**
   * 改变流程参数的reality
   */
  changeParamTypeReality(realityCode) {
    let reality = this.realityList.find(item => {
      return item.code == realityCode;
    });
    this.currentParamOption.type.text = reality.name;
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时 当类型为 集合或树形时 需要设置 valueItems
   *@author: gongcaixia
   *@date: 2021-04-26 14:10:03
   *@params1: 当前行信息
   */
  setDataSurceForFlowParam(row) {
    this.currentParamOption = row;
    this.setValueItemsDialogFlag = true;
    if (this.currentParamOption.type && this.currentParamOption.type.valueItems && this.currentParamOption.type.valueItems.length) {
      this.valueItems = this.currentParamOption.type.valueItems;
    } else {
      this.valueItems = [];
    }
  }

  /*
   *@description:  流程设置弹框内, 流程参数设置的 切换参数类型时， 当类型为集合时，要设置 valueItems的弹框的添加按钮事件
   *@author: gongcaixia
   *@date: 2021-04-26 14:51:30
   */
  addDataSourceForCollectorParam() {
    this.valueItems.push({
      text: '',
      code: '',
      disabled: false
    });
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为集合时，要设置 valueItems的弹框的表格 删除事件
   *@author: gongcaixia
   *@date: 2021-04-26 14:51:30
   *@variable1: 行索引
   */
  deleteDataSourceForCollectorParam(index) {
    this.valueItems.splice(index, 1);
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 添加事件
   *@author: gongcaixia
   *@date: 2021-04-26 15:07:48
   */
  addNodeForTreeParam(data) {
    const newChild = {
      code: '',
      text: '',
      children: [],
      disabled: false
    };
    if (!data) {
      this.valueItems.push(newChild);
    } else {
      data.children.push(newChild);
    }
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 删除事件
   *@author: gongcaixia
   *@date: 2021-04-26 15:07:48
   */
  removeNodeForTreeParam(node, data) {
    const parent = node.parent;
    const children = parent.data.children || parent.data;
    const index = children.findIndex(d => d.id === data.id);
    children.splice(index, 1);
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 禁用事件
   *@author: gongcaixia
   *@date: 2021-04-26 15:07:48
   */
  setTreeNodeDisabled(data) {
    this.$set(data, 'disabled', !data.disabled);
    let message = data.disabled ? '该节点设置禁用成功！' : '该节点解禁成功';
    this.$message({
      type: 'success',
      message: message
    });
  }

  /**
   * 获取值选项
   */
  getDataSourceForParamItemUseThrottle = throttle(this.getDataSourceForParamItem, 1000, { leading: false, trailing: true });
  getDataSourceForParamItem(data, row) {
    if (data.action === ASYNC_SEARCH) {
      if (data.searchQuery) {
        let params = {
          formId: this.flowConfig.customBpmModel.config.formId,
          processId: this.flowConfig.id,
          paramsOption: row,
          text: data.searchQuery
        };
        getFlowParamDataSource(params).then((res: any) => {
          let result = [];
          if (res.code == '1' && res.data && res.data.length) {
            result = res.data;
          }
          // row.type.valueItems = result;
          data.callback(null, result);
        });
      } else {
        data.callback(null, []);
      }
    }
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 确认事件
   *@author: gongcaixia
   *@date: 2021-04-26 17:19:40
   */
  confirmValueItems() {
    if (this.currentParamOption.type.reality === 'custom_options') {
      this.currentParamOption.type.valueItems = this.valueItems;
    } else {
      // this.currentParamOption.type.valueItems = [];
    }
    this.setValueItemsDialogFlag = false;
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的表格删除按钮事件
   *@author: gongcaixia
   *@date: 2021-04-25 13:35:10
   *@params1: 点击删除的行索引
   */
  deleteParamsConfigItem(row) {
    this.flowConfig.customBpmModel.config.paramsOptions.map((item, i) => {
      if (item.name == row.name) {
        this.flowConfig.customBpmModel.config.paramsOptions.splice(i, 1);
        return;
      }
    });
  }

  /**
   * 点击内部参数设置按钮时
   */
  showInnerParamsDialog(currentParamOption) {
    this.currentParamOption = currentParamOption;
    this.innerParamDialogVisiable = true;
  }

  /*
   *@description: 流程设置参数 数组内元素值为空时，数组置空
   *@author: gongcaixia
   *@date: 2021-04-29 18:02:42
   */
  resetConfig() {
    if (this.flowConfig.customBpmModel.config.paramsOptions.length > 0) {
      let item = this.flowConfig.customBpmModel.config.paramsOptions[this.flowConfig.customBpmModel.config.paramsOptions.length - 1];
      let arr = Object.keys(item);
      for (let i = 0; i < arr.length; i++) {
        let key = arr[i];
        if ((key == 'name' || key == 'type') && (!item[key] || JSON.stringify(item[key]) == '{}')) {
          // 如果名称和类型没填写，则置空数组
          this.flowConfig.customBpmModel.config.paramsOptions = [];
        }
      }
    }
    if (this.flowConfig.customBpmModel.config.autoOperations.length > 0) {
      let item = this.flowConfig.customBpmModel.config.autoOperations[this.flowConfig.customBpmModel.config.autoOperations.length - 1];
      let arr = Object.keys(item);
      for (let i = 0; i < arr.length; i++) {
        let key = arr[i];
        if (!item[key]) {
          this.flowConfig.customBpmModel.config.autoOperations = [];
        }
      }
    }
  }

  // 关闭弹窗
  handleColseDialog() {
    this.$emit('update:visible', false);
  }

  innerParamsOptions(paramsTypes: string[]) {
    return this.flowConfig.customBpmModel.config.paramsOptions.filter(item => {
      return (
        item.name != this.currentParamOption.name &&
        (!paramsTypes || paramsTypes.length == 0 || (item.type && paramsTypes.indexOf(item.type.truthType) > -1))
      );
    });
  }

  unGlobalFormParamsOptions() {
    return this.formParamsOptions.filter(item => {
      return !item.global;
    });
  }

  unFormImportParamsOptions(processParamName) {
    if (processParamName) {
      let processParam = this.formParamsOptions.find(item => {
        return processParamName == item.name;
      });
      return this.flowConfig.customBpmModel.config.paramsOptions.filter(item => {
        if (processParam) {
          if (processParam.type.truthType != item.type.truthType) {
            return false;
          }
        }
        return !item.formImport;
      });
    }
  }

  /**
   * 过滤-流程参数设置-表格数据：hidden字段等于true时 隐藏
   */
  tableDataFilter(val: Array<any>): Array<any> {
    return val.filter((item: any) => !item.hidden);
  }

  @Watch('visible')
  visibleWatcher(val) {
    // 弹窗显示
    if (val) {
      // 获取表单列表
      this.buildDataSourceForParams();
      this.getFlowConfig();
      this.getFormList();
      setTimeout(() => {
        this.flowConfigDialogVisiable = val;
      }, 300);
    } else {
      this.flowConfigDialogVisiable = val;
    }
  }
}
