




























































import {
  CodePartModel,
  CodeSettingData,
  CodeSettingModel,
  ConstantsPart,
  ItemType,
  ParamOptionData,
  ParamOptionModel,
  SerialNumberPart,
  SerialType,
  VariablePart
} from '@/models/flow/FlowConfigModel';
import { Component, Emit, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import VariableSelector from '@/components/flow-design/flow-config/node-variable-selector.vue';
@Component({
  name: 'code-generator-setting',
  components: {
    VariableSelector
  }
})
export default class CodeGeneratorSetting extends Vue {
  // 接受父组件的编号设置
  @Prop({
    required: false,
    default: () => {
      return CodeSettingData;
    }
  })
  codeSettingData!: CodeSettingModel;

  // 接受父组件的参数
  @Prop({
    required: false,
    default: () => {
      return ParamOptionData;
    }
  })
  paramsOptions!: ParamOptionModel[];

  // 编号组成部分类型
  itemTypes: Record<string, string>[] = [];

  // 流水号类型
  serialTypes: Record<string, string>[] = [];

  getItemTypes(): void {
    for (var innertype in ItemType) {
      let itemType: Record<string, string> = {
        key: innertype,
        label: this.$t(ItemType[innertype]) as string
      };
      this.itemTypes.push(itemType);
    }
  }

  getSerialTypes(): void {
    for (var innertype in SerialType) {
      let serialType: Record<string, string> = {
        key: innertype,
        label: SerialType[innertype]
      };
      this.serialTypes.push(serialType);
    }
  }

  addCodePart(): void {
    let part: CodePartModel = {
      type: ''
    };
    (part as SerialNumberPart).serialNumber = {
      type: '',
      length: 1
    };
    (part as ConstantsPart).value = '';
    (part as VariablePart).varName = '';
    this.codeSettingData.parts.push(part);
  }

  removeCodePart(index): void {
    this.codeSettingData.parts.splice(index, 1);
  }

  mounted() {}

  created() {
    this.getItemTypes();
    this.getSerialTypes();
  }

  @Watch('codeSettingData', { deep: true })
  private onCodeSettingDataChange(codeSettingData) {
    codeSettingData.parts.map(item => {
      if (item.type == 'CONSTANTS') {
        item.varName = null;
        item.serialNumber = null;
      } else if (item.type == 'SERIAL_NUMBER') {
        item.varName = null;
        item.value = null;
        if (item.serialNumber == null) {
          item.serialNumber = {
            type: '',
            length: 1
          };
        }
      } else if (item.type == 'VARIABLE') {
        item.serialNumber = null;
        item.value = null;
      }
    });
  }
}
