var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("lang_process_settings"),
        visible: _vm.flowConfigDialogVisiable,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        width: "90%",
        top: "5vh",
      },
      on: { close: _vm.handleColseDialog },
    },
    [
      _vm.flowConfigDialogVisiable
        ? _c(
            "el-form",
            {
              ref: "form",
              staticClass: "flow-config-form",
              attrs: { "label-width": "100px" },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticStyle: { color: "#f4bb21" } }, [
                        _vm._v(_vm._s(_vm.$t("lang_process_form_settings"))),
                      ]),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_process_name") } },
                            [
                              _c("lang-input", {
                                model: {
                                  value: _vm.flowConfig.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flowConfig, "name", $$v)
                                  },
                                  expression: "flowConfig.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_form") } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t("lang_please"),
                                    clearable: "",
                                  },
                                  on: { change: _vm.changeForm },
                                  model: {
                                    value:
                                      _vm.flowConfig.customBpmModel.config
                                        .formId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.flowConfig.customBpmModel.config,
                                        "formId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "flowConfig.customBpmModel.config.formId",
                                  },
                                },
                                _vm._l(_vm.formList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label:
                                        item.displayName || _vm.$t(item.name),
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_process_description"),
                              },
                            },
                            [
                              _c("lang-input", {
                                attrs: { type: "textarea", rows: 4 },
                                model: {
                                  value: _vm.flowConfig.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flowConfig, "description", $$v)
                                  },
                                  expression: "flowConfig.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_sort") } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0 },
                                model: {
                                  value: _vm.flowConfig.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flowConfig, "sort", $$v)
                                  },
                                  expression: "flowConfig.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticStyle: { color: "#f4bb21" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("lang_process_parameter_setting"))
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: {
                            type: "text",
                            disabled:
                              !_vm.flowConfig.customBpmModel.config.formId,
                            title: _vm.$t("lang_please_select_a_form_first"),
                          },
                          on: { click: _vm.showFormItemImportDialog },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lang_import_form_items")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: { type: "text", size: "small" },
                          on: { click: _vm.addFlowParam },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_add_manually")))]
                      ),
                    ],
                    1
                  ),
                  _vm.visible
                    ? _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "empty-text": _vm.$t("lang_no_data"),
                            data: _vm.tableDataFilter(
                              _vm.flowConfig.customBpmModel.config.paramsOptions
                            ),
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("lang_parameter_coding"),
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "small",
                                          placeholder:
                                            _vm.$t("lang_please_enter"),
                                        },
                                        model: {
                                          value: scope.row.name,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "name", $$v)
                                          },
                                          expression: "scope.row.name",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              643742264
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": 100,
                              label: _vm.$t("lang_parameter_name"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("lang-input", {
                                        attrs: {
                                          size: "small",
                                          placeholder:
                                            _vm.$t("lang_please_enter"),
                                        },
                                        model: {
                                          value: scope.row.text,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "text", $$v)
                                          },
                                          expression: "scope.row.text",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2205585295
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("lang_parameter_type"),
                              align: "center",
                              "class-name": "params-item-type",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          style: {
                                            width: _vm.isCollectorOrTree(
                                              scope.row
                                            )
                                              ? "calc(100% - 30px)"
                                              : "100%",
                                          },
                                          attrs: {
                                            size: "small",
                                            placeholder:
                                              _vm.$t("lang_please_select"),
                                          },
                                          on: {
                                            change: function (val) {
                                              return _vm.changeFlowParamType(
                                                val,
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.type.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row.type,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.type.value",
                                          },
                                        },
                                        _vm._l(
                                          _vm.paramsTypes,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: _vm.$t(item.text),
                                                value: item.value,
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.changeFormItemDataType(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              _vm.$t("lang_djjxglxxdsjsz"),
                                            placement: "right",
                                          },
                                        },
                                        [
                                          _vm.isCollectorOrTree(scope.row)
                                            ? _c("i", {
                                                staticClass: "el-icon-setting",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setDataSurceForFlowParam(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1288160232
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("lang_parameter_defaults"),
                              align: "center",
                              width: "300px",
                              "class-name": "params-item-value",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.isCollectorOrTree(scope.row)
                                        ? [
                                            scope.row.type.reality ==
                                            "custom_options"
                                              ? _c("treeselect", {
                                                  ref:
                                                    "defaultValue_" +
                                                    scope.$index,
                                                  staticClass: "cus-treeselect",
                                                  attrs: {
                                                    multiple: "",
                                                    limit: 1,
                                                    flat: "",
                                                    appendToBody: "",
                                                    zIndex: "999999",
                                                    "value-consists-of": "ALL",
                                                    options:
                                                      scope.row.type.valueItems,
                                                    normalizer:
                                                      _vm.normalizerHandler,
                                                    noOptionsText:
                                                      _vm.$t("lang_no_data"),
                                                    noResultsText: _vm.$t(
                                                      "lang_no_search_results"
                                                    ),
                                                    placeholder: _vm.$t(
                                                      "lang_set_default_values"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.defaultValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "defaultValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.defaultValue",
                                                  },
                                                })
                                              : _c("treeselect", {
                                                  ref:
                                                    "defaultValue_" +
                                                    scope.$index,
                                                  staticClass: "cus-treeselect",
                                                  attrs: {
                                                    multiple: "",
                                                    limit: 1,
                                                    flat: "",
                                                    appendToBody: "",
                                                    zIndex: "999999",
                                                    "value-consists-of": "ALL",
                                                    defaultOptions:
                                                      scope.row.type.valueItems,
                                                    async: true,
                                                    "load-options": function (
                                                      val
                                                    ) {
                                                      return _vm.getDataSourceForParamItemUseThrottle(
                                                        val,
                                                        scope.row
                                                      )
                                                    },
                                                    normalizer:
                                                      _vm.normalizerHandler,
                                                    noOptionsText:
                                                      _vm.$t("lang_no_data"),
                                                    noResultsText: _vm.$t(
                                                      "lang_no_search_results"
                                                    ),
                                                    placeholder:
                                                      _vm.$t(
                                                        "lang_please_select"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.defaultValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "defaultValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.defaultValue",
                                                  },
                                                }),
                                          ]
                                        : scope.row.type &&
                                          scope.row.type.value == "NUMBER"
                                        ? _c("el-input-number", {
                                            attrs: {
                                              size: "small",
                                              placeholder: _vm.$t(
                                                "lang_autu_end_condition_value"
                                              ),
                                            },
                                            model: {
                                              value: scope.row.defaultValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "defaultValue",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "scope.row.defaultValue",
                                            },
                                          })
                                        : scope.row.type &&
                                          scope.row.type.value == "DATE"
                                        ? _c("el-date-picker", {
                                            attrs: {
                                              size: "small",
                                              clearable: true,
                                              placeholder:
                                                _vm.$t("lang_please_select"),
                                              format: "yyyy-MM-dd",
                                              type: "date",
                                              "value-format": "timestamp",
                                            },
                                            model: {
                                              value: scope.row.defaultValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "defaultValue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.defaultValue",
                                            },
                                          })
                                        : scope.row.type &&
                                          scope.row.type.value == "FILE"
                                        ? _c("file-input", {
                                            attrs: {
                                              size: "small",
                                              placeholder: _vm.$t(
                                                "lang_autu_end_condition_value"
                                              ),
                                            },
                                            model: {
                                              value: scope.row.defaultValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "defaultValue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.defaultValue",
                                            },
                                          })
                                        : _c("el-input", {
                                            attrs: {
                                              size: "small",
                                              placeholder: _vm.$t(
                                                "lang_set_default_values"
                                              ),
                                            },
                                            model: {
                                              value: scope.row.defaultValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "defaultValue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.defaultValue",
                                            },
                                          }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3329048959
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("lang_operation"),
                              align: "center",
                              width: "200px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button-group",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-right": "6px",
                                              },
                                              attrs: {
                                                type: "text",
                                                disabled:
                                                  scope.row.type.value !=
                                                  "DEFINITION",
                                                round: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showInnerParamsDialog(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lang_Internal_parameters"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                disabled: scope.row.global,
                                                round: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteParamsConfigItem(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lang_delete"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3417900929
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.flowConfig.customBpmModel.config.formId
                ? _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("span", { staticStyle: { color: "#f4bb21" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("lang_form_parameter_mapping"))
                            ),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "10px",
                              },
                              attrs: { type: "text", size: "small" },
                              on: { click: _vm.addFormParamSetting },
                            },
                            [_vm._v(_vm._s(_vm.$t("lang_new")))]
                          ),
                        ],
                        1
                      ),
                      _vm.visible
                        ? _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "empty-text": _vm.$t("lang_no_data"),
                                data: _vm.flowConfig.customBpmModel.config
                                  .formParamSettings,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("lang_form_parameter_mapping"),
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                size: "small",
                                                placeholder: _vm.$t(
                                                  "lang_please_select_form_parameters"
                                                ),
                                              },
                                              model: {
                                                value: scope.row.formParamName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "formParamName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.formParamName",
                                              },
                                            },
                                            _vm._l(
                                              _vm.unGlobalFormParamsOptions(),
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.name,
                                                  attrs: {
                                                    label: item.text,
                                                    value: item.name,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  101794022
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("lang_process_parameters"),
                                  align: "center",
                                  "class-name": "params-item-type",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                size: "small",
                                                placeholder: _vm.$t(
                                                  "lang_please_select_process_parameters"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  scope.row.processParamName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "processParamName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.processParamName",
                                              },
                                            },
                                            _vm._l(
                                              _vm.unFormImportParamsOptions(
                                                scope.row.formParamName
                                              ),
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.name,
                                                  attrs: {
                                                    label: _vm.$t(item.text),
                                                    value: item.name,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1677314815
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticStyle: { color: "#f4bb21" } }, [
                        _vm._v(_vm._s(_vm.$t("lang_process_number_setting"))),
                      ]),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c("code-generator-setting", {
                            attrs: {
                              paramsOptions:
                                _vm.flowConfig.customBpmModel.config
                                  .paramsOptions,
                              codeSettingData:
                                _vm.flowConfig.customBpmModel.config
                                  .codeSetting,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleColseDialog } }, [
            _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveFlowConfig } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_setting"),
            "append-to-body": "",
            visible: _vm.setValueItemsDialogFlag,
            "close-on-click-modal": false,
            "destroy-on-close": true,
            width: "60%",
            "custom-class": "datasource-config-dlg",
          },
          on: {
            "update:visible": function ($event) {
              _vm.setValueItemsDialogFlag = $event
            },
          },
        },
        [
          _c("el-divider", { staticClass: "cus-divider" }, [
            _vm._v(_vm._s(_vm.$t("lang_source_type"))),
          ]),
          _c(
            "div",
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("lang_please_select")) + "：")]),
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-left": "6px" },
                  on: { change: _vm.changeParamTypeReality },
                  model: {
                    value: _vm.currentParamOption.type.reality,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentParamOption.type, "reality", $$v)
                    },
                    expression: "currentParamOption.type.reality",
                  },
                },
                _vm._l(_vm.realityList, function (item) {
                  return _c(
                    "el-radio",
                    { key: item.code, attrs: { label: item.code } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.currentParamOption.type.reality == "custom_options"
            ? [
                _c("el-divider", { staticClass: "cus-divider" }, [
                  _vm._v(_vm._s(_vm.$t("lang_source_type"))),
                ]),
                _vm.currentParamOption.type.value ==
                _vm.widgetDataTypeEnum.COLLECTOR
                  ? [
                      _c("div", { staticClass: "node-action-container" }, [
                        _c("div", { staticClass: "node-action-header" }, [
                          _c(
                            "div",
                            { staticClass: "node-action-header-item" },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("lang_name")))])]
                          ),
                          _c(
                            "div",
                            { staticClass: "node-action-header-item" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("lang_number_"))),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "node-action-header-item" },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("lang_state")))])]
                          ),
                          _c(
                            "div",
                            { staticClass: "node-action-header-item" },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-plus",
                                  size: "mini",
                                  circle: "",
                                },
                                on: {
                                  click: _vm.addDataSourceForCollectorParam,
                                },
                              }),
                              _c("el-button", {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-brush",
                                  size: "mini",
                                  circle: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "node-action-content" },
                          _vm._l(_vm.valueItems, function (btnItem, btnIndex) {
                            return _c(
                              "div",
                              { key: btnIndex, staticClass: "node-action-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "node-action-item" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder:
                                          _vm.$t("lang_please_enter"),
                                        size: "small",
                                      },
                                      model: {
                                        value: btnItem.text,
                                        callback: function ($$v) {
                                          _vm.$set(btnItem, "text", $$v)
                                        },
                                        expression: "btnItem.text",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "node-action-item" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder:
                                          _vm.$t("lang_please_enter"),
                                      },
                                      model: {
                                        value: btnItem.code,
                                        callback: function ($$v) {
                                          _vm.$set(btnItem, "code", $$v)
                                        },
                                        expression: "btnItem.code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "node-action-item" },
                                  [
                                    _c("el-switch", {
                                      attrs: { size: "small" },
                                      model: {
                                        value: btnItem.disabled,
                                        callback: function ($$v) {
                                          _vm.$set(btnItem, "disabled", $$v)
                                        },
                                        expression: "btnItem.disabled",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "node-action-item" },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-plus",
                                        size: "small",
                                        circle: "",
                                      },
                                      on: {
                                        click:
                                          _vm.addDataSourceForCollectorParam,
                                      },
                                    }),
                                    _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-delete",
                                        size: "small",
                                        circle: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  : _vm.currentParamOption.type.value ==
                    _vm.widgetDataTypeEnum.TREE
                  ? [
                      _c(
                        "div",
                        { staticClass: "node-action-container" },
                        [
                          _c("div", { staticClass: "node-action-header" }, [
                            _c(
                              "div",
                              { staticClass: "node-action-header-item" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lang_name"))),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "node-action-header-item" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lang_number_"))),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "node-action-header-item" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lang_state"))),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "node-action-header-item" },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-plus",
                                    size: "mini",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addNodeForTreeParam(null)
                                    },
                                  },
                                }),
                                _c("el-button", {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-brush",
                                    size: "mini",
                                    circle: "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.valueItems.length
                            ? _c("el-tree", {
                                ref: "appTreeRef",
                                staticClass: "node-action-tree",
                                attrs: {
                                  data: _vm.valueItems,
                                  props: {
                                    label: "text",
                                    children: "children",
                                  },
                                  "default-expand-all": "",
                                  "expand-on-click-node": false,
                                  "highlight-current": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var node = ref.node
                                        var data = ref.data
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "node-action-row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-action-item",
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t(
                                                          "lang_please_enter"
                                                        ),
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value: data.text,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data,
                                                          "text",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "data.text",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-action-item",
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t(
                                                          "lang_please_enter"
                                                        ),
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value: data.code,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data,
                                                          "code",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "data.code",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-action-item",
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: { size: "small" },
                                                    model: {
                                                      value: data.disabled,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data,
                                                          "disabled",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.disabled",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-action-item",
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      type: "text",
                                                      icon: "el-icon-plus",
                                                      size: "mini",
                                                      circle: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addNodeForTreeParam(
                                                          data
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("el-button", {
                                                    attrs: {
                                                      type: "text",
                                                      icon: "el-icon-delete",
                                                      size: "mini",
                                                      circle: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeNodeForTreeParam(
                                                          node,
                                                          data
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  638036101
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setValueItemsDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmValueItems },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_Internal_parameters"),
            "append-to-body": "",
            visible: _vm.innerParamDialogVisiable,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerParamDialogVisiable = $event
            },
          },
        },
        [
          _vm._l(_vm.currentParamOption.innerParams, function (item) {
            return _c("variable-selector", {
              key: item.key,
              attrs: {
                selectorKey: item.key,
                variableTitle: item.text,
                variableType: item.type,
                paramsOptions: _vm.innerParamsOptions(item.types),
              },
              model: {
                value: item.paramOptionName,
                callback: function ($$v) {
                  _vm.$set(item, "paramOptionName", $$v)
                },
                expression: "item.paramOptionName",
              },
            })
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.innerParamDialogVisiable = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.innerParamDialogVisiable = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.flowConfig.customBpmModel.config.formId
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("lang_import_form_items"),
                "append-to-body": "",
                visible: _vm.importFormDialogVisible,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.importFormDialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  staticStyle: { height: "300px" },
                  attrs: { "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_form_item") } },
                    [
                      _c("treeselect", {
                        attrs: {
                          multiple: true,
                          appendToBody: "",
                          zIndex: "9999",
                          "disable-branch-nodes": true,
                          options: _vm.formWidgetTree,
                          valueFormat: "object",
                          normalizer: _vm.formWidgetTreeFormat,
                          placeholder: _vm.$t("lang_please_select"),
                        },
                        on: { open: _vm.isSelectedForm },
                        model: {
                          value: _vm.selectedFormItemValue,
                          callback: function ($$v) {
                            _vm.selectedFormItemValue = $$v
                          },
                          expression: "selectedFormItemValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.importFormDialogVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveFormItemConfig },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }