var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("lang_process_settings"),
        visible: _vm.visible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        width: "70%",
        top: "5vh",
      },
      on: { close: _vm.handleColseDialog },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "flow-config-form",
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticStyle: { color: "#f4bb21" } }, [
                    _vm._v(_vm._s(_vm.$t("lang_process_form_settings"))),
                  ]),
                ]
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_process_name") } },
                        [
                          _vm.visible
                            ? _c("lang-input", {
                                model: {
                                  value: _vm.formData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "name", $$v)
                                  },
                                  expression: "formData.name",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("lang_process_description") },
                        },
                        [
                          _vm.visible
                            ? _c("lang-input", {
                                attrs: { type: "textarea", rows: 4 },
                                model: {
                                  value: _vm.formData.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "description", $$v)
                                  },
                                  expression: "formData.description",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_sort") } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0 },
                            model: {
                              value: _vm.formData.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "sort", $$v)
                              },
                              expression: "formData.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleColseDialog } }, [
            _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveFlowConfig } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }