














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { workflowModelUpdateEditable } from '@/api/flow-design/FlowApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { FlowData, FlowModel } from '@/models/flow/FlowModel';

@Component({
  name: 'FlowBaseInfoConfig',
  components: {}
})
export default class FlowBaseInfoConfig extends Vue {
  // 应用id
  @Prop({ default: '' }) applicationId!: string;

  @Prop({
    default: SystemUtil.cloneDeep(FlowData)
  })
  flowConfig!: FlowModel;

  @Prop({ default: false })
  visible!: boolean; // 流程设置弹框 的显示隐藏

  // 表单数据
  formData: any = {
    id: '',
    name: '',
    description: '',
    sort: 0
  };

  /**
   * 保存
   * @param {}
   * @returns {}
   */
  saveFlowConfig() {
    workflowModelUpdateEditable(this.formData).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.handleColseDialog();
        this.$emit('success', {});
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 关闭弹窗
  handleColseDialog() {
    this.$emit('update:visible', false);
  }

  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      this.formData.id = this.flowConfig.id;
      this.formData.name = this.flowConfig.name;
      this.formData.description = this.flowConfig.description;
      this.formData.sort = this.flowConfig.sort;
    }
  }
}
